<template>
    <div class="container-fluid p-0">
        <!-- main content -->
        <!-- 编辑区域 -->
        <div class="m-md-3">
            <div class="row my-3">
                <div class="col-md-6 offset-md-3">
                    <div class="card no-b">
                        <div class="card-body">
                            <h5 class="card-title">修改登录密码</h5>
                            
                            <div>
                                <div class="form-group">
                                    <label for="inputAddress" class="col-form-label">帐号</label>
                                    <input autocomplete="off" class="form-control" v-model="user_name" disabled="disabled" placeholder="帐号" type="text">
                                </div>
                                <div class="form-group">
                                    <label for="inputAddress" class="col-form-label">原密码</label>
                                    <input autocomplete="new-password" class="form-control " v-model=" old_password" placeholder="原密码"
                                     type="password">
                                </div>
                                
                                <div class="form-group">
                                    <label for="inputAddress" class="col-form-label">新密码</label>
                                    <input autocomplete="new-password" class="form-control " v-model="password" placeholder="新密码"
                                     type="password">
                                </div>
                                
                                <div class="form-group">
                                    <label for="inputAddress2" class="col-form-label">确认新密码</label>
                                    <input autocomplete="new-password" class="form-control " v-model="re_password" placeholder="确认新密码" type="password">
                                </div>
        
                                <button type="button" :disabled="disabled" class="btn btn-primary btn-block" @click.stop="save">保存</button>
        
                            </div>
        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- //编辑区域 -->
        <!-- //main content -->
    </div>
</template>

<script>
    module.exports = {
        data(){
            return{
                title: '帐号与安全',
                
                token: '',
                outlet_id: 0,
                outlet_name:'',
                user_name: '',
                
                employeelist: [],
                employee: {
                    disabled: false,
                    category_id: 0,
                },
                selectedIndex: -1,
                
                disabled: false,
                isFirst: false,
                
                categorylist: [],
                
                old_password: '',
                password: '',
                re_password: '',
				
				dept_id: '',
            }
        },
        created: function() {
            console.log('--- created --- ')
            let self = this;
            
            //1.检查登录
            let token = localStorage.getItem('TOKEN');
            if (!token) {
                return;
            }
            self.token = token;
            
            //2.取得店铺信息
            let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
            console.log(lsLoginData.logined_outlet_name)
            console.info('--- this.$store.state ->',this.$store.state)
            // self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.dept_id = lsLoginData.dept_id;
            self.user_name = lsLoginData.user_account;
        },
        mounted: function() {
            console.log('--- mounted --- ')
            // let self = this;
            
            //1.检查登录
            // let token = localStorage.getItem('TOKEN');
            // if (!self.token) {
            //     console.log('--- mounted jump  --- ')
            //     location.href = 'index.html';
            //     return;
            // }
			
        },
        methods: {
            
            save(){
                let self = this;
                console.log(self.password);
                // return;
                
                //校验
                if(!self.old_password){
                    // alert('原密码不能为空')
					self.$toasted.error( '原密码不能为空', {
						position: 'top-center',
						duration: '2000',
					})
                    return;
                }
                
                if(!self.password){
                    // alert('新密码不能为空')
					self.$toasted.error( '新密码不能为空', {
						position: 'top-center',
						duration: '2000',
					})
                    return;
                }
                
                if(!self.re_password){
                    // alert('请确认新密码')
					self.$toasted.error( '请确认新密码', {
						position: 'top-center',
						duration: '2000',
					})
                    return;
                }
                
                if(self.re_password != self.password){
                    // alert('两次输入不一致')
					self.$toasted.error( '两次输入不一致', {
						position: 'top-center',
						duration: '2000',
					})
                    return;
                }
                
                self.disabled = true;
                
                // 组装数据
                let postData = {
                    token: self.token,
                    old_password: self.old_password,
                    new_password: self.password,
                }
                
                this.axios.get(this.GLOBAL.baseURI + 'modifyEntUser', {
                    params: postData
                })
                .then(function(response) {
                    console.log(response);
                    return response.data;
                })
                .catch(function(error) {
                    console.log(error);
                })
                .then(function(data) {
                    // always executed
                    console.log(data.data);
                    
                    self.disabled = false;
                    
                    if (data.code == 200) { 
                        // alert('修改成功，退出重新登录');
						self.$toasted.success( '修改成功，退出重新登录', {
							position: 'top-center',
							duration: '2000',
						})
                        
						setTimeout(()=>{
							 self.logout();
						},2000)
                       
                    }
                    else if (data.code == 3002) {
                        // alert('原密码错误');
						self.$toasted.error( '原密码错误', {
							position: 'top-center',
							duration: '2000',
						})
                    }
                    else if (data.code == 101) {
                        //登录超时
                        // location.href = "inddex.html";
						self.logoff();
                    }
                    else{
                        // alert(data.message)
						self.$toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
                    }
                });
            },
            
            
            logout() {
                //TODO:调用 logout 接口，清除服务器端登录状态
                
                localStorage.clear();
                // location.href = 'index.html';
				this.$router.push({name:'login'})
            },
            
            onOutletChangeListener(e){
                let self = this;
                console.log('--- outlet ID->',e)
                self.outlet_id = e;
                
                self.init(() => {
                    self.initData();
                });
            },
        },
    }
</script>

<style>
</style>
